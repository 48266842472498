import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import Select from "react-select";
import { Redirect } from "react-router-dom";
import formValidation from "../../validations/es6/core/Core";
import { opt } from "./validationOpt";
import {
  Input,
  Card,
  Row,
  CardBody,
  Col,
  Form,
  FormGroup,
  Label,
  Button,
  Alert,
  CardTitle,
  FormText,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  CustomInput,
} from "reactstrap";
import { getCookie } from "../../jwt/_helpers/cookie";
import { getUser } from "../../utils/getUser";
import ean from "../../validations/es6/validators/ean";
import PreviewFiles from "./previewFiles";
import { useLoading, Audio } from '@agney/react-loading';

import { StateContext } from "../../utils/context";
import AsyncDropdownNormal from "../../components/asyncDropdownNormal";
import AsyncSelect from 'react-select/async';

import StoreLinksRow from "./storeLinksRow";

import { useFetch } from "../../utils/fetchHook.js";

import classnames from 'classnames';

import {
  pvTrackNameValidators,
  pvArtistNameValidators,
  getStoreValidators,
} from './validators.js';
import LandingPagePreview from "./landingPagePreview.js";

import CustomColorPickerInput from "../../components/customColorPickerInput.js";
import CustomColorPickerGradient from "../../components/customColorGradientInput.js";


const API_URL = process.env.REACT_APP_API_URL_BASE;
const X_API_KEY = process.env.REACT_APP_X_API_KEY;
const MUSIC_LINK_URL = process.env.REACT_APP_MUSIC_LINK_URL;

const validUrlRegex = /^(?:https?|http):\/\/(?:[^\s\/]+\.)?[^\s\/]+\.[^\s\/]+(?:\/[^\/\s]*)*$/;

const INITIAL_PREVIEW_FILES = [];

const SOURCE_OPTIONS = [
  {
    value: 1,
    label: "Existing Release",
  },
  { value: 2, label: "External Release" },
];

const defaultHeaderGradient = {"first_color": "#b40bbe", "second_color": "#000a60", "direction": 1};

let gradientDegreeDict = {
  1: "180deg",
  2: "90deg",
  3: "135deg",
  4: "225deg",
  5: "270deg",
  6: "0deg"
};

const createFormObject = (params) => {
  const formData = new FormData();
  for (const key in params) {
    formData.set(key, params[key]);
  }
  return formData;
};

const validateEanUpc = (eanUpc) => {
  let validEanUpc = ean().validate({value: eanUpc});
  return validEanUpc.valid;
}

const validateAlbumURL = (url) => {
  if (typeof url !== 'string') {
    return false;
  }
  if(url.includes("?"))
  {
    url = url.split("?")[0];
  }
  let deezerPattern = /^(?:https?:\/\/)?(?:www\.)?deezer\.com\/(?:\w{2}\/)?album\/\d+\/?$/i;
  let spotifyPattern = /^(?:https?:\/\/)?(?:open\.)?spotify\.com\/album\/[a-zA-Z0-9]+\/?$/i;
  return deezerPattern.test(url) || spotifyPattern.test(url);
}

const openInNewTab = url => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const storeLabelDict = {
  "amazonmusic": "Amazon Music",
  "applemusic": "Apple Music",
  "beatport": "Beatport",
  "deezer": "Deezer",
  "junodownload": "Junodownload",
  "soundcloud": "Soundcloud",
  "spotify": "Spotify",
  "tidal": "Tidal",
  "traxsource": "Traxsource",
  "youtube": "YouTube",
  "anghami": "Anghami",
  "bandcamp": "Bandcamp",
  "boomplay": "Boomplay",
  "iheartradio": "iHeartRadio",
  "jiosaavn": "JioSaavn",
  "sevendigital": "7digital"
};

const buttonStyleOptions = [
  { value: 1, label: "Outlined" },
  { value: 2, label: "Filled" },
];

const getStoreLabel = (value) => {
  return storeLabelDict[value] || value.charAt(0).toUpperCase() + value.slice(1);
};

const AddMusicLink = () => {
  const token = getCookie("token");
  const [forceUpdate, setForce] = useState(0);
  const didEff = useRef(false);
  const sourceSelectRef = useRef();
  const options = {
    method: "GET",
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${token}`,
      "x-api-key": X_API_KEY,
      "Content-Type": "application/json"
    }
  };

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const [formError, setFormError] = useState(false);

  const [source, setSource] = useState("");
  const [previewFiles, setPreviewFiles] = useState(INITIAL_PREVIEW_FILES);
  const [urlPartOne, setUrlPartOne] = useState(null);
  const [urlPartTwo, setUrlPartTwo] = useState(null);
  const [selectedSearchOption, setSelectedSearchOption] = useState("upc");
  const [releaseDetails, setReleaseDetails] = useState(null);
  const [isloading, isloadingSET] = useState(false);

  const {currentUser, setCurrentUser} = useContext(StateContext);
  const [parentUserOnly, setParentUserOnly] = useState('');
  const [subUser, setSubUser] = useState('');
  const [subUserId, setSubUserId] = useState(null);
  const [subUserEndpoint, setSubUserEndpoint] = useState('');

  const [releaseData, setReleaseData] = useState(null);

  const [premadeColors, pmcError, pmcIsLoading] = useFetch("GET", "ml-premade-colors/?page_size=9999", token, false);
  const [premadeColorOptions, setPremadeColorOptions] = useState([]);
  const [premadeColorSelected, setPremadeColorSelected] = useState(null);
  const [customColorSelected, setCustomColorSelected] = useState(null);

  const [colorScheme, setColorScheme] = useState(1);

  const [headerColor, setHeaderColor] = useState("#ffffff");
  const [headerGradient, setHeaderGradient] = useState(defaultHeaderGradient);
  const [titleColor, setTitleColor] = useState("#ffffff");
  const [descriptionColor, setDescriptionColor] = useState("#ffffff");
  const [buttonColor, setButtonColor] = useState("#000a60");
  const [buttonTextColor, setButtonTextColor] = useState("#000A60");
  const [playerControlsColor, setPlayerControlsColor] = useState("#ffffff");

  const [headerSingleColorSelected, headerSingleColorSelectedSET] = useState(true);
  const [headerGradientSelected, headerGradientSelectedSET] = useState(false);

  const [btnStyle, setBtnStyle] = useState({ value: 1, label: "Outlined"});

  const [listOfStoreLinks, listOfStoreLinksSET] = useState([
    { order: 0,  store: { value: "amazonmusic", label: "Amazon Music" }, link: "", key: makeid(20) },
    { order: 1,  store: { value: "applemusic", label: "Apple Music" }, link: "", key: makeid(20) },
    { order: 2,  store: { value: "beatport", label: "Beatport" }, link: "", key: makeid(20) },
    { order: 3,  store: { value: "deezer", label: "Deezer" }, link: "", key: makeid(20) },
    { order: 4,  store: { value: "junodownload", label: "Junodownload" }, link: "", key: makeid(20) },
    { order: 5,  store: { value: "soundcloud", label: "Soundcloud" }, link: "", key: makeid(20) },
    { order: 6,  store: { value: "spotify", label: "Spotify" }, link: "", key: makeid(20) },
    { order: 7,  store: { value: "tidal", label: "Tidal" }, link: "", key: makeid(20) },
    { order: 8,  store: { value: "traxsource", label: "Traxsource" }, link: "", key: makeid(20) },
    { order: 9,  store: { value: "youtube", label: "YouTube" }, link: "", key: makeid(20) },
  ]);

  const fvRef = useRef(null);
  const formErrRef = useRef();
  const storeLinksListREF = useRef();

  storeLinksListREF.current = listOfStoreLinks;
  formErrRef.current = formError;

  const linkOrderChange = (type, key, index, order, statename, setStateName) => {
    const current = { key: key, index: index, order: order };
    let sibling;

    if (type === "up") {
      if (index === 0) {
        return false
      } else {
        sibling = {
          key: statename[index - 1].key,
          index: index - 1,
          order: statename[index - 1].order
        };
      }
    } else if (type === "down") {
      if (index + 1 === statename.length) {
        return false
      } else {
        sibling = {
          key: statename[index + 1].key,
          index: index + 1,
          order: statename[index + 1].order
        };
      }
    }

    const newList = statename.map((element, i) => {
      if (element.key === current.key) {
        element.order = sibling.order;
      }
      if (element.key === sibling.key) {
        element.order = current.order;
      }
      return element;
    });
    setStateName(newList.sort((a, b) => a.order - b.order))
  }

  const manageStoreLinksData = (index, key, value) => {
    const newList = listOfStoreLinks.map((element, i) => {
      if (index === i) {
        element[key] = value;
      }
      return element;
    });
    listOfStoreLinksSET(newList);
  };

  const addNewStoreLink = (store) => {
    let storeObj = { order: listOfStoreLinks.length, store: store, link: "", key: makeid(20) };
    listOfStoreLinksSET([...listOfStoreLinks, storeObj]);
    if(fvRef.current)
    {
      let fv = fvRef.current;
      let fieldName = `${store.value}_url`;
      let storeValidators = {
        validators: {
            notEmpty: {
              message: `${store.label} Url is required"`
            },
            regexp: {
                regexp: validUrlRegex,
                message: `Please add a valid ${store.label} Url.`,
            },
        }
      };
      fv.addField(fieldName, storeValidators);
    }
  }

  const removeStoreLink = (index) => {
    if (listOfStoreLinks.length === 0) return false;

    let storeLinkToRemove = null;

    const newList = listOfStoreLinks.filter((element, i) => {
      if (index === i) {
        storeLinkToRemove = element;
        return false;
      } else {
        if (i < index) {
          return element;
        } else {
          element.order = element.order - 1;
          return element;
        }
      }
    });
    listOfStoreLinksSET(newList);    
    if(fvRef.current && storeLinkToRemove !== null)
    {
      let fv = fvRef.current;
      fv.removeField(`${storeLinkToRemove.store.value}_url`);
    }
  }

  const updateAllStoreLinks = (result) => {
    const mandatoryStoreValues = [
      "amazonmusic",
      "applemusic",
      "beatport",
      "deezer",
      "junodownload",
      "soundcloud",
      "spotify",
      "tidal",
      "traxsource",
      "youtube"
    ];
  
    const storeUpdates = [
      { store: "amazonmusic", url: result?.data?.amazonmusic_url || "" },
      { store: "applemusic", url: result?.data?.applemusic_url || "" },
      { store: "beatport", url: result?.data?.beatport_url || "" },
      { store: "deezer", url: result?.data?.deezer_url || "" },
      { store: "junodownload", url: result?.data?.junodownload_url || "" },
      { store: "soundcloud", url: result?.data?.soundcloud_url || "" },
      { store: "spotify", url: result?.data?.spotify_url || "" },
      { store: "tidal", url: result?.data?.tidal_url || "" },
      { store: "traxsource", url: result?.data?.traxsource_url || "" },
      { store: "youtube", url: result?.data?.youtube_url || "" },
      { store: "anghami", url: result?.data?.anghami_url || "" },
      { store: "bandcamp", url: result?.data?.bandcamp_url || "" },
      { store: "boomplay", url: result?.data?.boomplay_url || "" },
      { store: "iheartradio", url: result?.data?.iheartradio_url || "" },
      { store: "jiosaavn", url: result?.data?.jiosaavn_url || "" },
      { store: "sevendigital", url: result?.data?.sevendigital_url || "" }
    ];
  
    const newListOfStoreLinks = mandatoryStoreValues.map((store, index) => {
      const update = storeUpdates.find(update => update.store === store);
      return {
        order: index,
        store: { value: store, label: getStoreLabel(store) },
        link: update ? update.url : "",
        key: makeid(20)
      };
    });
  
    const filteredStoreUpdates = storeUpdates.filter(update => update.url !== "" && !mandatoryStoreValues.includes(update.store));
  
    filteredStoreUpdates.forEach(update => {
      newListOfStoreLinks.push({
        order: newListOfStoreLinks.length,
        store: { value: update.store, label: getStoreLabel(update.store) },
        link: update.url,
        key: makeid(20)
      });
    });
  
    newListOfStoreLinks.sort((a, b) => a.store.label.localeCompare(b.store.label));
  
    listOfStoreLinksSET(newListOfStoreLinks);
  };

  const storeLinkProps = {
    listOfStoreLinks,
    manageStoreLinksData,
    listOfStoreLinksSET,
    addNewStoreLink,
    removeStoreLink,
    linkOrderChange,
  };

  const axiosOptions = {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${token}`,
      "x-api-key": X_API_KEY,
      "Content-Type": "application/json",
    },
  };

  const { containerProps, indicatorEl } = useLoading({
    loading: isloading,
    loaderProps: {
      style: { color: 'white', padding: "80px 45% 80px 45%"},
      valueText: 'Fetching Release Details...',
    },
    indicator: <center><div style={{ textAlign: 'center', fontFamily: 'Nunito Sans' }}><Audio width="150" vertical-align="middle" className="mb-4" /><br/><h3>Fetching Release Details</h3><br/></div></center>,
  })

  const revalidateField = (name) => {
    if(fvRef.current) {
      let fv = fvRef.current;
      clearValidationErrors("addMusicLinkForm", name);
      fv.revalidateField(name);
    }
  }

  const clearValidationErrors = (formName, validatorName) => {
    let formID = document.getElementById(formName);
    if(formID) {
      const messages = [].slice.call(formID.querySelectorAll('[data-field="' + validatorName + '"][data-validator]'));
      messages.forEach((messageEle) => {
        messageEle.style.display = 'none';
      });
    }
  }

  useEffect(() => {
    getUser(token, currentUser, setCurrentUser);
  }, [success, error]);



  useEffect(() => {
    if(releaseDetails) {
      let releaseUpc = releaseDetails.upc;
      if(releaseUpc !== "") {
        setReleaseData(null);
        getEanInformation(releaseDetails.upc);
      }
    }
  }, [releaseDetails]);


  useEffect(() => {
    if(premadeColors.results && premadeColors.results.length>0)
    {
      setPremadeColorSelected(premadeColors.results[0]);
      setPremadeColorOptions(premadeColors.results);
    }
  }, [premadeColors]);


  useEffect(() => {
    let colorSelected = premadeColorSelected;
    if(colorScheme === 2) {
      colorSelected = customColorSelected;
    }

    if(colorSelected!==null)
    {
      setTitleColor(colorSelected.title_color);
      setDescriptionColor(colorSelected.description_color);
      setButtonColor(colorSelected.button_color);
      setButtonTextColor(colorSelected.button_text_color);
      setHeaderColor(colorSelected.header_color);
      setHeaderGradient(colorSelected.header_gradient!==null? colorSelected.header_gradient: defaultHeaderGradient);
      setPlayerControlsColor(colorSelected.player_controls_color);
      if(colorSelected.header_color_scheme === 1) {
        headerSingleColorSelectedSET(true);
        headerGradientSelectedSET(false);
      }
      else {
        headerSingleColorSelectedSET(false);
        headerGradientSelectedSET(true);
      }
    }
  }, [customColorSelected, premadeColorSelected, colorScheme]);



  useEffect(() => {
    if (!didEff.current) {
      didEff.current = true;
      return;
    }
    setForce(prev => prev + 1);
  }, [subUserId]);

  useEffect(() => {
    if(!isloading && releaseData) {
      let formID = document.getElementById("addMusicLinkForm");
      let fv = formValidation(formID, opt);
      fvRef.current = fv;
      fv.on("core.element.validated", function(e) {
        if (e.valid) {
          if (e.field === "url_part2") {
            setUrlPartTwo(e.element.value);
          } else {
            setUrlPartTwo("");
          }
          if (formErrRef.current){
            setFormError(false);
            const messages = [].slice.call(formID.querySelectorAll('[data-field="' + e.field + '"][data-validator]'));
            messages.forEach((messageEle) => {
                messageEle.style.display = 'none';
            });
            return;
          }
        } else {
          setFormError(true);
          return;
        }
      })
      .on("core.field.invalid", function(e) {
        if (e) {
          setFormError(true);
          return;
        }
      })
      .on("core.validator.validated", function(e) {
        const item = e.field;
        if (!e.result.valid) {
          const messages = [].slice.call(formID.querySelectorAll('[data-field="' + e.field + '"][data-validator]'));
          for(let i = 0; i < messages.length - 1; i++) {
            const messageEle = messages[i];
            messageEle.style.display = 'none';
          }
        }
      })
      .on("core.form.valid", async e => {
  
        let urlPart2 = formID.querySelector('[name="url_part2"]').value;
  
        const dataObject = {
          url_part1: urlPartOne.value,
          url_part2: urlPart2,
          preview_files: previewFiles,
          source: source,
          artist_name: formID.querySelector('[name="artist_name"]').value,
          release_name: formID.querySelector('[name="release_name"]').value,
          ean: formID.querySelector('[name="ean"]').value,
          image_big: releaseData.image_big,
          image_small: releaseData.image_small,
          other_url: "",
          // info: formID.querySelector('[name="info"]').value,
          ...(subUserId && { sub_user_id: subUserId || null}),
          color_scheme: colorScheme,
          premade_color: colorScheme === 1 ? premadeColorSelected.id: null,
          custom_color: colorScheme === 2 ? {
            name: `Colors for Music Link - ${formID.querySelector('[name="release_name"]').value}`,
            header_color: headerColor,
            header_color_scheme: headerSingleColorSelected? 1: 2,
            header_gradient: headerSingleColorSelected? null: headerGradient,
            title_color: titleColor,
            description_color: descriptionColor,
            button_color: buttonColor,
            button_text_color: buttonTextColor,
            player_controls_color: playerControlsColor,
            created_by: currentUser.id,
          }: null,
          button_style: btnStyle.value,
        };
  
        listOfStoreLinks.map((store_link) => {
          const storeKey =  store_link.store.value;
          dataObject[`${storeKey}_url`] = store_link.link? store_link.link: "";
        });
    
        axios({
          url: `${API_URL}musiclinks/`,
          data: dataObject,
          ...axiosOptions,
        })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            setSuccess(true);
            setTimeout(() => setRedirect(true), 1000);
          } else {
            console.log(response);
            setError(true);            
          }
        })
        .catch((error) => {
          console.log(error);
          setError(true);
        });
      })
      .on('core.form.invalid', function(event) {
        setFormError(true);
      });

      if(previewFiles) {
        previewFiles.map((prvFile, index) => {
          let fv = fvRef.current;
          let indexToAdd = index;
          fv.addField('preview_file[' + indexToAdd + '].track_name', pvTrackNameValidators).addField('preview_file[' + indexToAdd + '].artist_name', pvArtistNameValidators);
        });
      }

      if(listOfStoreLinks) {
        listOfStoreLinks.map((storeLink, index) => {
          let fv = fvRef.current;
          let fieldName = `${storeLink.store.value}_url`;
          let storeValidators = getStoreValidators(storeLink.store.label);
          fv.addField(fieldName, storeValidators);
        });
      }
    }
  }, [isloading, listOfStoreLinks, previewFiles, releaseData, urlPartOne, btnStyle]);

  const processResults = (result) => {
    if(result !== null) {
      const relData = {};
      relData["ean"] = result?.data?.ean ?? "";
      relData["image_big"] = result?.data?.image_big ?? "";
      relData["image_small"] = result?.data?.image_small ?? "";
      relData["artist_name"] = result?.data?.artist_name ?? "";
      relData["release_name"] = result?.data?.release_name ?? result?.data?.release_name ?? "";
      relData["other_url"] = "";
      // relData["info"] = "";
      relData["url_part1"] = null;
      relData["url_part2"] = "";
  
      updateAllStoreLinks(result);
      
      setUrlPartOne(null);
      setReleaseData(relData);
      let pvFiles = result?.data?.preview_files ?? []
      if (pvFiles.length) {
        setPreviewFiles(pvFiles);
      }
    } else {
      setReleaseData(null);
      setPreviewFiles(INITIAL_PREVIEW_FILES);
    }
    isloadingSET(false);
  };

  const getEanInformation = async (
    ean
  ) => {
    isloadingSET(true);
    const options = {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${token}`,
        "x-api-key": X_API_KEY,
      },
    };
    try {
      const result = await axios({
        url: `${API_URL}release-dist-ean/`,
        data: createFormObject({ ean: ean }),
        ...options,
      });
      if (result.status === 200) {
        processResults(result);
      }
    } catch (error) {
      console.log(error);
      processResults(null);
    }
  };

  const addNewPreviewFile = (newPreviewFiles) => {
    setPreviewFiles(newPreviewFiles);
  };

  const removePreviewFile = (index) => {
    const newPreviewFiles = JSON.parse(JSON.stringify(previewFiles));
    newPreviewFiles.splice(index, 1);
    setPreviewFiles(newPreviewFiles);
  };

  const manageDataFilePreview = (index, name, value) => {
    const newPreviewFiles = JSON.parse(JSON.stringify(previewFiles));
    newPreviewFiles[index][name] = value;
    setPreviewFiles(newPreviewFiles);
  };

  const onReleaseChange = async (
    id
  ) => {
    isloadingSET(true);
    setReleaseData(null);
    setPreviewFiles(INITIAL_PREVIEW_FILES);
    try {
      const result = await axios.get(
        `${API_URL}releases_dist/${id}/`,
        axiosOptions
      );
      if (result.status === 200) {
        processResults(result);
      }
    } catch (error) {
      console.log(error);
      processResults(null);
    }
  };

  return (
        <div>
          <Row>
            <Col xl={9} lg={7} md={7} sm={7} xm={6}>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <CardTitle tag="h4">Add Music Link</CardTitle>
                    <div className="mt-3">
                      <FormGroup row>
                        <Label for="source" sm={2}>
                          <b>Source</b>
                        </Label>
                        <Col>
                          <Select
                            ref={sourceSelectRef}
                            components={{ IndicatorSeparator: () => null }}
                            options={SOURCE_OPTIONS}
                            onChange={(e)=> {
                              setSource(e.value);
                              setReleaseDetails(null);
                              setPreviewFiles(INITIAL_PREVIEW_FILES);
                              processResults(null);
                              if(e.value === 2) {
                                setSubUser('');
                                setSubUserId(null);
                                setSubUserEndpoint('');
                                setParentUserOnly('');
                              }
                            }}
                          />
                        </Col>
                      </FormGroup>

                      { (currentUser.is_premium_user)  &&
                        <FormGroup className="select-search-wrap" row>
                          <Label
                            sm={2}
                          >
                            <b>Sub-User</b>
                          </Label>
                          <Col>
                            {
                              subUser ?
                                <div className="releaseFileRow">
                                    <p className="releaseFileRowName"> {subUser}</p>
                                    <Button className="btn btn-outline-info" 
                                      onClick={() => {
                                        setSubUser('');
                                        setSubUserId(null);
                                        setSubUserEndpoint('');
                                        setParentUserOnly('');
                                        if(source === 1) {
                                          processResults(null);
                                          setPreviewFiles(INITIAL_PREVIEW_FILES);
                                        }
                                      }}
                                    >
                                      Reset
                                    </Button>
                                </div>
                                :
                              <AsyncDropdownNormal
                                fetchOptions={options}
                                endpoint={`sub-users`}
                                subUserEndpoint={subUserEndpoint}
                                parentUserOnly={parentUserOnly}
                                labelField="username"
                                onChange={e => {
                                  let subUserId = e.value;
                                  let username = e.label;
                                  if(subUserId !== '') {
                                    setSubUser(username);
                                    setSubUserId(subUserId);
                                    setSubUserEndpoint(`sub-user/${subUserId}/`);
                                    setParentUserOnly('');
                                  } else {
                                    setSubUser('');
                                    setSubUserId(null);
                                    setSubUserEndpoint('');
                                    setParentUserOnly('');
                                  }
                                  if(source === 1) {
                                    processResults(null);
                                    setPreviewFiles(INITIAL_PREVIEW_FILES);
                                  }
                                }}
                                placeholder="Select Sub-user..."
                              />
                            }
                          <FormText color="muted">
                            Note: Only use this dropdown if you want to Add Music Link for any of your Sub-users otherwise leave un-selected.
                          </FormText>
                          </Col>
                        </FormGroup>
                      }

                      {source === 1 &&
                        <FormGroup row>
                          <Label for="release_name" sm={2}>
                            <b>Release</b>
                          </Label>
                          <Col>
                            <AsyncDropdownNormal
                              fetchOptions={options}
                              endpoint={`releases`}
                              subUserEndpoint={subUserEndpoint}
                              parentUserOnly={parentUserOnly}
                              queryParams={"&page_size=9999&status=distributed"}
                              labelField="name"
                              onChange={e => {
                                onReleaseChange(e.value);
                              }}
                              placeholder="Select Release"
                            />
                            <FormText color="muted">
                              Note: You can only select releases which are <b>Distributed</b>.
                            </FormText>
                          </Col>
                        </FormGroup>
                      }

                      {(source === undefined || source === 2) && (
                        <>
                          {
                            (selectedSearchOption === "upc")  &&
                            <FormGroup className="select-search-wrap" row>
                              <Label for="Release" sm={2}>
                                <b>Release</b>
                              </Label>
                              <Col>
                                <AsyncSelect
                                  cacheOptions
                                  defaultOptions
                                  defaultValue={releaseDetails? releaseDetails.value: null}
                                  loadOptions={(inputValue, callback) => {
                                    let isUpcValid = validateEanUpc(inputValue);
                                    if (!inputValue || inputValue.trim() === '' || !isUpcValid) {
                                      callback([]);
                                      return;
                                    }
                                    fetch(`${API_URL}releases/search-release/?upc=${inputValue}`, options)
                                    .then(response => {
                                      if (!response.ok) {
                                        return false;
                                      } else {
                                        return response.json();
                                      }
                                    })
                                    .then(searchRes => {
                                      if (searchRes) {
                                        let store = searchRes.store;
                                        let albums = searchRes.data;
                                        if(store === "spotify") {
                                          callback(albums.map(({ name, id, images }) => ({ value: id, label: name, image: images[0].url, upc: inputValue, store: store })));
                                        }
                                        else if(store === "deezer") {
                                          callback(albums.map(({ title, id, cover_medium, upc }) => ({ value: id, label: title, image: cover_medium, upc: inputValue, store: store })));
                                        }
                                        else {
                                          callback([]);
                                          return;
                                        }
                                      }
                                      else {
                                        callback([]);
                                        return;
                                      }
                                    });
                                  }}
                                  formatOptionLabel={album => (
                                    <div>
                                      <img
                                        src={album.image? album.image: ""}
                                        alt={album.label}
                                        style={{
                                            height: '40px',
                                            width: '40px',
                                            borderRadius: '30%',
                                            marginRight: "10px",
                                        }}
                                      />
                                      <b>{album.label}</b>
                                    </div>
                                  )}
                                  onChange={(selectedOption) => {
                                    setReleaseDetails(selectedOption);
                                  }}
                                  placeholder="Enter Release UPC/EAN Number i.e. 5055964352523"
                                  styles={{
                                    maxWidth: '100%',
                                    menu: (styles) => ({ ...styles, zIndex: 10 }),
                                  }}
                                />
                                <FormText color="muted">
                                  Note: Only use this dropdown if you want to add a Release that is already on Spotify or Deezer.
                                </FormText>
                              </Col>
                            </FormGroup>
                          }

                          {
                            (selectedSearchOption === "url") &&
                            <FormGroup className="select-search-wrap" row>
                              <Label for="Release" sm={2}>
                                <b>Release</b>
                              </Label>
                              <Col>
                                <AsyncSelect
                                  id="transfer-by-url"
                                  cacheOptions
                                  defaultOptions
                                  defaultValue={releaseDetails? releaseDetails.value: null}
                                  loadOptions={(inputValue, callback) => {
                                    let validAlbumUrl = validateAlbumURL(inputValue);
                                    if (!inputValue || inputValue.trim() === '' || !validAlbumUrl) {
                                      callback([]);
                                      return;
                                    }
                                    let store = inputValue.includes("deezer.com")? "deezer": "spotify";
                                    if(inputValue.includes("?"))
                                    {
                                      inputValue = inputValue.split("?")[0];
                                    }
                                    fetch(`${API_URL}releases/search-release/?store=${store}&url=${inputValue}`, options)
                                    .then(response => {
                                      if (!response.ok) {
                                        return false;
                                      } else {
                                        return response.json();
                                      }
                                    })
                                    .then(searchRes => {
                                      if (searchRes) {
                                        let albums = [searchRes];
                                        if(store==="spotify") {
                                          callback(albums.map(({ name, id, images, external_ids }) => ({ value: id, label: name, image: images[0].url, upc: external_ids.hasOwnProperty("upc") ? external_ids["upc"] : external_ids.hasOwnProperty("ean") ? external_ids["ean"] : null, store: store })));
                                        }
                                        else if(store==="deezer") {
                                          callback(albums.map(({ title, id, cover_medium, upc }) => ({ value: id, label: title, image: cover_medium, upc: upc, store: store })));
                                        }
                                        else {
                                          callback([]);
                                          return;
                                        }
                                      }
                                    });
                                  }}
                                  formatOptionLabel={album => (
                                    <div>
                                      <img
                                        src={album.image? album.image: ""}
                                        alt={album.label}
                                        style={{
                                          height: '40px',
                                          width: '40px',
                                          borderRadius: '30%',
                                          marginRight: "10px",
                                        }}
                                      />
                                      <b>{album.label}</b>
                                    </div>
                                  )}
                                  onChange={(selectedOption) => {
                                    setReleaseDetails(selectedOption);
                                  }}
                                  placeholder="Enter Release Store Url i.e. open.spotify.com/album/4pAD0l5icphM5TC1A4q8Yn or www.deezer.com/en/album/413370367"
                                  styles={{
                                    maxWidth: '100%',
                                    menu: (styles) => ({ ...styles, zIndex: 10 }),
                                  }}
                                />
                                <FormText color="muted">
                                  Note: Only use this dropdown if you want to add a Release that is already on Spotify or Deezer.
                                </FormText>
                              </Col>
                            </FormGroup>
                          }

                          <FormGroup row>
                            <Label for="Spotify By" sm={2}>
                              <b>Search By</b>
                            </Label>
                            <Col>
                              <div style={{ display: "flex", justifyContent: "normal" }} >
                                <FormGroup check className="mr-4">
                                  <Label check>
                                    <Input type="radio" name="search-by-upc" value="upc" disabled={releaseDetails!==null} checked={selectedSearchOption === "upc"} onChange={() => {setSelectedSearchOption("upc")}} />{" "}
                                    <span className="mt-1">UPC Number</span>
                                  </Label>
                                </FormGroup>
                                <FormGroup check className="mr-4">
                                  <Label check>
                                    <Input type="radio" name="search-by-url" value="url" disabled={releaseDetails!==null} checked={selectedSearchOption === "url"} onChange={() => {setSelectedSearchOption("url")}} />{" "}
                                    <span className="mt-1">Store Release URL</span>
                                  </Label>
                                </FormGroup>
                                {
                                  releaseDetails &&
                                  <FormGroup>
                                    <Button className="btn btn-outline-info"
                                      onClick={() => { 
                                        setReleaseDetails(null);
                                        processResults(null);
                                        setPreviewFiles(INITIAL_PREVIEW_FILES);
                                      }}
                                    >Reset</Button>
                                  </FormGroup>
                                }
                              </div>
                            </Col>
                          </FormGroup>
                        </>
                      )}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Row {...containerProps}>
            <Col className="col-12">
              <div className="bg-primary ml-loading">
                {indicatorEl}
              </div>
            </Col>
          </Row>

          {
            (!isloading && releaseData) &&
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Form className="mt-3" id="addMusicLinkForm">
                      {releaseData?.image_big && (
                        <FormGroup row>
                          <Label sm={2}>Artwork</Label>
                          <Col xs={2}>
                            <img
                              src={releaseData.image_big}
                              style={{ width: "300px", padding: "10px 0" }}
                              alt={"Release Artwork"}
                            />
                          </Col>
                        </FormGroup>
                      )}

                      <FormGroup>
                        <Row>
                          <Col sm={2}>
                            <Label for="release_name">
                              Release Name
                            </Label>
                          </Col>
                          <Col sm={10}>
                            <Input
                              defaultValue={releaseData?.release_name}
                              name="release_name"
                              disabled={releaseData?.release_name? true: false}
                              type="text"
                            />
                          </Col>
                        </Row>
                      </FormGroup>

                      <FormGroup>
                        <Row>
                          <Col sm={2}>
                            <Label for="ean">
                              UPC/EAN
                            </Label>
                          </Col>
                          <Col sm={10}>
                            <Input
                              defaultValue={releaseData?.ean}
                              name="ean"
                              disabled={releaseData?.ean? true: false}
                              type="text"
                            />
                          </Col>
                        </Row>
                      </FormGroup>

                      <FormGroup>
                        <Row>
                          <Col sm={2}>
                            <Label for="artist_name">
                              Artist Name
                            </Label>
                          </Col>
                          <Col sm={10}>
                            <Input
                              defaultValue={releaseData?.artist_name}
                              name="artist_name"
                              type="text"
                            />
                          </Col>
                        </Row>
                      </FormGroup>            

                      {/* <FormGroup>
                        <Row>
                          <Col sm={2}>
                            <Label for="info">
                              Info
                            </Label>                
                          </Col>
                          <Col sm={10}>
                            <Input
                              defaultValue={releaseData?.info}
                              name="info"
                              type="textarea"
                              component="textarea"
                              style={{ minHeight: "80px" }}
                            />
                          </Col>
                        </Row>
                      </FormGroup> */}

                      <FormGroup>
                        <Row>
                          <Col sm={2}>
                            <Label for="url_part1">
                              Url part1
                            </Label>
                          </Col>
                          <Col sm={10}>
                            <Row>
                              <Col xs={10}>
                                <AsyncDropdownNormal
                                  fetchOptions={options}
                                  endpoint={`musiclinks_urlparts`}
                                  subUserEndpoint={subUserEndpoint}
                                  parentUserOnly={parentUserOnly}
                                  labelField="part"
                                  onChange={e => {
                                    setUrlPartOne(e);
                                    revalidateField("url_part1");
                                  }}
                                  placeholder="Select Url Part 1"
                                />
                                <Input name="url_part1" type="text" className="form-control" hidden={true} disabled={true} defaultValue={urlPartOne? urlPartOne.label: ""} />
                              </Col>
                              <Col xs={2}>
                                <Button color="success"
                                  className="btn btn-outline-success"
                                  style={{width: '100%', minWidth: "100px"}}
                                  onClick={() => {
                                    openInNewTab("/music-link-urlpart")
                                  }}
                                >
                                  Create New?
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </FormGroup>

                      <FormGroup>
                        <Row>
                          <Col sm={2}>
                            <Label for="url_part2">
                              Url part2
                            </Label>
                          </Col>
                          <Col sm={10}>
                            <Input
                              defaultValue={releaseData?.url_part2}
                              name="url_part2"
                              type="text"
                              disabled={urlPartOne === null}
                              onFocus={() => {revalidateField("url_part2")}}
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                      
                      {
                        urlPartOne && urlPartTwo &&
                        <p>
                          Your music link URL is:&nbsp;
                          <span style={{ textDecoration: "underline", color: "blue", cursor: "not-allowed" }}>
                            {`${MUSIC_LINK_URL}/l/${urlPartOne.label}/${urlPartTwo}/`}
                          </span>
                        </p>
                      }

                      <div style={{marginTop: "50px", marginBottom: "50px"}}>
                        <Label><b>Color Customisation</b></Label>
                        <Nav tabs style={{border: "1px solid #e9ecef", borderRadius: "5px", padding: "4px"}}>
                          <NavItem
                              className={"color-selection-nav-item" + classnames({
                                'color-selection-nav-item-active': colorScheme !== 1
                              })}>
                            <NavLink
                              className={classnames({
                                'active': colorScheme === 1
                              })}
                              onClick={() => {
                                setColorScheme(1)
                                setCustomColorSelected({
                                  title_color: titleColor,
                                  description_color: descriptionColor,
                                  button_color: buttonColor,
                                  button_text_color: buttonTextColor,
                                  header_color: headerColor,
                                  header_gradient: headerGradient!==null? headerGradient: defaultHeaderGradient,
                                  player_controls_color: playerControlsColor,
                                  header_color_scheme: headerSingleColorSelected? 1: 2,
                                })
                                if(premadeColorSelected === null)
                                {
                                  setPremadeColorSelected(premadeColorOptions[0])
                                }
                                else {
                                  setPremadeColorSelected(premadeColorSelected)
                                }
                              }}
                            >
                              <b>Premade Colors</b>
                            </NavLink>
                          </NavItem>
                          <NavItem
                              className={"color-selection-nav-item" + classnames({
                                'color-selection-nav-item-active': colorScheme !== 2
                              })}>
                            <NavLink
                              className={classnames({
                                'active': colorScheme === 2
                              })}
                              onClick={() => {
                                setColorScheme(2)
                                if(customColorSelected === null)
                                {
                                  setCustomColorSelected(premadeColorSelected)
                                }
                                else {
                                  setCustomColorSelected(customColorSelected)
                                }
                              }}
                            >
                              <b>Custom Colors</b>
                            </NavLink>
                          </NavItem>
                        </Nav>

                        <TabContent activeTab={colorScheme} style={{border: "1px solid #e9ecef", borderRadius: "5px", padding: "4px"}}>
                          <TabPane tabId={1} className="mt-3 p-3">
                            <Row>
                              <Col sm="12">
                                <div style={{display: "flex", flexWrap: "wrap", alignItems: "right", width: "100%"}}>
                                  {
                                    premadeColorOptions.map(pmColor => {
                                        let isPmColorSelected = (pmColor?.id === premadeColorSelected?.id)
                                        let firstCirleBackgroundStyle = {backgroundColor: pmColor.header_color};
                                        let secondCirleBackgroundStyle = {backgroundColor: pmColor.button_color};

                                        if(pmColor.header_color_scheme === 2 && pmColor.header_gradient!==null) {
                                          let headerGradientDirection = gradientDegreeDict[pmColor.header_gradient.direction];
                                          firstCirleBackgroundStyle = {
                                            "background": pmColor.header_gradient.first_color,
                                            "background": `-moz-linear-gradient(${headerGradientDirection}, ${pmColor.header_gradient.first_color}, ${pmColor.header_gradient.second_color})`,
                                            "background": `-webkit-linear-gradient(${headerGradientDirection}, ${pmColor.header_gradient.first_color}, ${pmColor.header_gradient.second_color})`,
                                            "background": `linear-gradient(${headerGradientDirection}, ${pmColor.header_gradient.first_color}, ${pmColor.header_gradient.second_color}`,
                                            "filter": `progid:DXImageTransform.Microsoft.gradient(startColorstr="${pmColor.header_gradient.first_color}",endColorstr="${pmColor.header_gradient.second_color}",GradientType=1)`,
                                          };
                                        }

                                        return (
                                          <div className="premade-color-container" onClick={()=> {setPremadeColorSelected(pmColor)}} key={pmColor.id}>
                                            <div className="premade-color-circle-container">
                                              <div className="premade-color-circle" style={{...firstCirleBackgroundStyle, borderColor: isPmColorSelected? "#b40bbe": "grey"}}></div>
                                              <div className="premade-color-circle" style={{...secondCirleBackgroundStyle, borderColor: isPmColorSelected? "#b40bbe": "grey"}}></div>
                                            </div>
                                            <h4 className="premade-color-name" style={{color: isPmColorSelected? "#b40bbe": "black"}}>{pmColor.name}</h4>
                                          </div>
                                        );
                                    })
                                  }
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm="12">
                                <FormGroup>
                                  <Label>Button Style</Label>
                                  <Select
                                    components={{ IndicatorSeparator:() => null }}
                                    name="button_style"
                                    options={buttonStyleOptions}
                                    value={btnStyle}
                                    onChange={(e) => {
                                      setBtnStyle(e);
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </TabPane>

                          <TabPane tabId={2} className="mt-3 p-3">
                            <Row>
                              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                  <FormGroup>
                                      <Label>Header Background</Label>
                                      <div style={{display: "flex", alignItems: "center", width: "100%", marginBottom: "10px"}}>
                                        <CustomInput
                                          type="radio"
                                          id="headerColorRadio"
                                          value="headerColorRadio"
                                          label="Single Color"
                                          name="headerColorRadio"
                                          checked={headerSingleColorSelected}
                                          onChange={e => {
                                            headerSingleColorSelectedSET(!headerSingleColorSelected);
                                            headerGradientSelectedSET(!headerGradientSelected);
                                          }}
                                        />
                                        <CustomInput
                                          className="ml-2"
                                          type="radio"
                                          id="headerGradientRadio"
                                          value="headerGradientRadio"
                                          label="Gradient"
                                          name="headerGradientRadio"
                                          checked={headerGradientSelected}
                                          onChange={e => {
                                            headerSingleColorSelectedSET(!headerSingleColorSelected);
                                            headerGradientSelectedSET(!headerGradientSelected);
                                            setHeaderGradient(headerGradient!==null? headerGradient: defaultHeaderGradient)
                                          }}
                                        />
                                    </div>
                                    {
                                      headerSingleColorSelected && <CustomColorPickerInput color={headerColor} setColor={setHeaderColor} />
                                    }
                                    {
                                      headerGradientSelected && <CustomColorPickerGradient gradient={headerGradient} setGradient={setHeaderGradient} />
                                    }
                                  </FormGroup>
                                </Col>
                                <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                    <FormGroup>
                                      <Label>Player Controls Color</Label>
                                      <CustomColorPickerInput color={playerControlsColor} setColor={setPlayerControlsColor} />
                                    </FormGroup>
                                  </Col>
                              </Row>
                            <Row>
                              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                  <FormGroup>
                                      <Label>Title Color</Label>
                                      <CustomColorPickerInput color={titleColor} setColor={setTitleColor} />
                                  </FormGroup>
                                </Col>
                                <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                  <FormGroup>
                                      <Label>Description Color</Label>
                                      <CustomColorPickerInput color={descriptionColor} setColor={setDescriptionColor} />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                  <FormGroup>
                                    <Label>Button Color</Label>
                                    <CustomColorPickerInput color={buttonColor} setColor={setButtonColor} />
                                  </FormGroup>
                                </Col>
                                <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                  <FormGroup>
                                    <Label>Button {btnStyle.value === 1 ? "Hover Color" : "Text Color"}</Label>
                                    <CustomColorPickerInput color={buttonTextColor} setColor={setButtonTextColor} />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                  <FormGroup>
                                    <Label>Button Style</Label>
                                    <Select
                                      components={{ IndicatorSeparator:() => null }}
                                      name="button_style"
                                      options={buttonStyleOptions}
                                      value={btnStyle}
                                      onChange={(e) => {
                                        setBtnStyle(e);
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                          </TabPane>
                        </TabContent>
                      </div>

                      <StoreLinksRow
                        values={storeLinkProps}
                        id="storeLinks"
                        name="storeLinks"
                      />

                      {/* <PreviewFiles
                        id="releaseTracks"
                        name="releaseTracks"
                        values={{
                          previewFiles,
                          addNew: addNewPreviewFile,
                          remove: removePreviewFile,
                          manageData: manageDataFilePreview,
                        }}
                      /> */}

                      <FormGroup row>
                        <Col>
                          <Button color="success">Submit</Button>
                        </Col>
                      </FormGroup>
                    </Form>
                    {
                      formErrRef.current &&
                      <p className="fv-help-block"  style={{marginTop: 15}}>Form cannot be submited! Please fill all fields and check errors!</p>
                    }
                    {success && (
                      <Alert color="success">Music link has been added!</Alert>
                    )}
                    {redirect ? <Redirect to="/music-link" /> : null}
                    {error && (
                      <Alert color="danger">
                        Something went wrong! Please refresh page and try again!
                      </Alert>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
          </Col>
          <Col xl={3} lg={5} md={5} sm={5} xm={6}>
            <LandingPagePreview 
              musicLinkData={{
                release_name: releaseData?.release_name,
                artist_name: releaseData?.artist_name,
                image_big: releaseData?.image_big? releaseData.image_big: null,
                image_small: releaseData?.image_small? releaseData.image_small: null,
                store_links: listOfStoreLinks,

                title_color: titleColor,
                color_scheme: colorScheme,
                header_color_scheme: headerSingleColorSelected? 1: 2,
                header_color: headerColor,
                header_gradient: headerGradient,
                description_color: descriptionColor,
                player_controls_color: playerControlsColor,
                button_color: buttonColor,
                button_text_color: buttonTextColor,
                button_style: btnStyle.value,
              }}
              isloading={isloading}
              previewFiles={previewFiles}
              previewFilesMissing={true}
            />
          </Col>
          </Row>
        </div>
  );
};

export default AddMusicLink;
